<template>
  <div id="tutorial-law-cast">
    <div class="content f-maru">
      <div class="tutorial-law text-left">
        <div class="card-tutorial-law">
          <div class="card-tutorial-law__content">
            <p class="card-tutorial-law__content-title text-center text-first">
              キャンセルについて
            </p>
            <p class="card-tutorial-law__content-text">
              予約するゴルフ場のプレー開始6時間前から45日先までの予約Superオファーが可能
            </p>
            <p class="card-tutorial-law__content-text">
              オーダーしていただき、マッチンがグ完了した後のキャンセル
              は、100％有償になります
            </p>
            <p class="card-tutorial-law__content-text">
              オーダーの変更キャンセルは、Superオファーを出して、一名以上の応募
              があった場合でも変更、キャンセルは可能になります。
            </p>
            <p class="card-tutorial-law__content-text">
              応募がなかったり、応募者が満たない場合は、設定していただ
              いた時間で自動キャンセルされます。
            </p>
            <p class="card-tutorial-law__content-text">
              プレー予定日の時間前にマッチングを確定いただかなかったり、
              Superオファーが満たない場合は、自動でキャンセルされます。
            </p>
            <p class="card-tutorial-law__content-text">
              当日の急な悪天候や、ゴルフ場のクローズによりキャンセルは、
              無償になりますが、当日集まってからの場合は、女性の交通費
              のみの支給になりますので、ご注意ください。
            </p>
            <hr class="hr-text" />
            <p
              class="
                card-tutorial-law__content-title-center
                text-center text-first
              "
            >
              マッチング後の流れについて
            </p>
            <p class="card-tutorial-law__content-text">
              参加キャストが確定すると、そのメンバーでメッセージ画面に
              トークルームが作られます。
            </p>
            <p class="card-tutorial-law__content-text">
              そちらでお客様から改めて、ゴルフ場を予約した後の「開催場
              所」「開催時間」「集合時間」「プレー時間」「要望」をお伝
              えください。
            </p>
            <p class="card-tutorial-law__content-text">
              ピックアップをご選択の際は、「集合場所」を相手と相談をし
              て決定してください。相手の個人情報もありますので、「集合
              場所」にはご配慮ください。
            </p>
            <!-- <p class="card-tutorial-law__content-title-center f-maru text-center">
                開始と終了、解散について
              </p> -->
            <p class="card-tutorial-law__content-text text-last">
              「現地集合」プランであれば、ゴルフ場エントランスでの合流が開始
            </p>
          </div>
          <!-- <div class="d-flex label justify-content-center align-items-center">
              <div
                class="
                  checkbox
                  d-flex
                  justify-content-center
                  align-items-center
                "
                id="checkbox"
                @click="check()"
              >
                <b-icon icon="check" v-if="checked" />
              </div>
              同意する
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <button type="submit" @click="nextTutorial()">
                <div
                  class="
                    position-relative
                    d-flex
                    align-items-center
                    button-back
                  "
                >
                  <div class="text-center posision-absolute w-100 text-center">
                    次へ
                  </div>
                  <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
                </div>
              </button>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorialLawCast",
  data() {
    return {
      checked: false
    };
  },
  methods: {
    check() {
      this.checked = !this.checked;
      if (this.checked) {
        document.getElementById("checkbox").style.backgroundColor = "#464D77";
      } else {
        document.getElementById("checkbox").style.backgroundColor = "#ffffff";
      }
    },
    changeStep() {
      this.$emit("changeStep");
    },
    returnLaw() {
      this.$emit("returnLaw");
    },
    nextTutorial() {
      if (this.checked) {
        this.$emit("tutorialLaw");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/tutorial/tutorialLaw";
@import "@/assets/scss/_fontFamily.scss";
</style>
