<template>
  <div class="law">
    <HeaderCommon ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <TutorialLaw />
    </div>
  </div>
</template>

<script>
import TutorialLaw from "@/components/Cast/Tutorial/TutorialLaw.vue";
import HeaderCommon from "@/components/CardHeader.vue";

export default {
  name: "Law",
  components: {
    HeaderCommon,
    TutorialLaw
  },
  metaInfo() {
    return {
      title: "利用規約",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "利用規約",
        isShowLogo: false,
        isShowTitle: true
      }
    };
  },
  mounted() {
    let layout = document.getElementById("layout-default");
    if (localStorage.getItem("step") == 1) {
      layout.style.backgroundImage =
        "url(" + require("@/assets/image/background/bgr-pc.png") + ")";
      let content = document.getElementById("app-container__content");
      content.style.overflow = "auto";
    } else {
      layout.style.backgroundImage =
        "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
    }
  },
  destroyed() {
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
    if (localStorage.getItem("step") == 1) {
      let content = document.getElementById("app-container__content");
      content.style.overflow = "hidden";
    }
  }
};
</script>
<style lang="scss" scoped>
.content-scroll {
  //overflow-y: scroll;
  padding-top: 40px;
  overflow-x: hidden;
}
.law {
  .app-container {
    height: auto;
    .app-container__content {
      height: auto;
    }
  }
}

@media screen and (min-width: 1200px) {
  .law {
    .app-container {
      height: 100vh;
      .app-container__content {
        height: 100vh;
      }
    }
  }
}
</style>
